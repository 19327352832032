import React from "react";

const Footer = () => {
    return (
        <div className="footer mobile">
            <p>&copy; 2020 Armando Roman</p>
        </div>
    );
};

export default Footer;
